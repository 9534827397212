.contact__section-container__section-title h2 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.contact__section-container__section-text p, .text__decoration {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
    margin-bottom: 1rem;
}

.contact__section-container__section-contact {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
    padding: 4rem 6rem;
}

.contact__section-container__section-contact__card {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
    text-align: left;
}

.contact__section-container__section-contact__card h4 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
    margin-bottom: 2rem;
 }

 .message_input {
     background-color: transparent;
     border: none;
     border-bottom: 1px solid var(--color-text);
     width: 100%;
     padding-top: 2rem;
     color: var(--color-text);
 }

 .container_message_input {
     padding-bottom: 4rem;
 }

 input:focus, textarea:focus, select:focus{
    outline: none;
}

.errorMessage {
    color: red;
    min-height: 1.5rem;
}
.successMessage {
    color: green;
    min-height: 1.5rem;
}

@media screen and (max-width: 1200px) {
    .contact__section-container__section-contact {
      padding: 2rem 2rem;
    }
  }