.aboutus__section-container__section {
    padding: 4rem 6rem;
}

.aboutus__section-container__section__who {
    padding-left: 6rem;
    padding-right: 6rem;
}

.aboutus__section-container__section-title h2 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.aboutus__section-container__section-text p, .text__decoration {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
    margin-bottom: 2rem;
}

.aboutus__section-container__section-mission-vision {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-top: 2rem;
    padding: 4rem 6rem;
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.aboutus__section-container__section-mission-vision__card {
    flex: 1 1;
    margin: 1rem;
    min-width: 210px;
    display: unset;
    flex-direction: column;
    text-align: center;
}

.aboutus__section-container__section-mission-vision__card h4 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
    margin-bottom: 0.5rem;
 }

 .aboutus__container_images {
     display: flex;
     flex-wrap: wrap;
     flex-direction: row;
     justify-content:space-around;
 }

 .aboutus__container_images img {
     width: 450px;
    margin-bottom: 30px;
 }
 /* .aboutus__container_images img:hover {

    width: 500px;
  
} */
 
@media screen and (max-width: 1200px) {
    .aboutus__section-container__section__who,
    .aboutus__section-container__section-mission-vision {
      padding: 0 2rem;
    }
  }

  @media screen and (max-width: 700px) {
    .aboutus__section-container__section__who,
    .aboutus__section-container__section-mission-vision {
      padding: 0 0rem;
    }
  }

  @media screen and (max-width: 560px) {
    .aboutus__section-container__section {
      padding: 0 2rem;
    }

    .aboutus__container_images img {
        width: 300px;
    }
  }