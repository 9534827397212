.services_section-container__section-title h2 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.services__section-container__section-text p, .text__decoration {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: var(--color-text);
}

.services__section-container__section-services {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 4rem 6rem;
    /* background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 4rem 6rem; */
}

.services__section-container__section-services__card {
    flex: 1 1;
    margin: 1rem;
    min-width: 50%;
    display: unset;
    flex-direction: column;
    text-align: center;
    background: radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin: 2rem 6rem;
    padding: 4rem 6rem;
}

.services__section-container__section-services__card h4 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
    margin-bottom: 0.5rem;
 }

 @media screen and (max-width: 1200px) {
    .services__section-container__section-services__card {
      margin: 2rem 2rem;
    }
  }

  @media screen and (max-width: 800px) {
    .services__section-container__section-services__card {
      margin: 2rem 1rem;
      padding: 4rem 4rem;
    }
    .services__section-container__section-services {
        padding: 4rem 2rem;
    }
  }
 
  @media screen and (max-width: 450px) {
    .services__section-container__section-services__card {
        margin: 2rem 1rem;
        padding: 2rem 1rem;
      }
    .services__section-container__section-services {
      padding: 2rem 1rem;
    }
  }